<template>
  <div class="factory" id="lshFactory">
    <div class="lsh-factory">
      <p class="lsh-title">
        数字化工厂
      </p>
      <div class="factory-main">
        <div class="factory-img-first">
          <img v-lazy="images.url1" alt="" style="width:100%;height:600px;">
        </div>
        <div class="factory-img-second">
          <img v-lazy="images.url2" alt="" style="width:100%;height:600px;">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data(){
      return {
        images: {
          url1: require('@/assets/img/factory1.jpg'),
          url2: require('@/assets/img/factory2.jpg')
        }
      }
    }
  }
</script>

<style lang="less">
  .factory{
    .lsh-factory{
      position: relative;
      z-index: 50;
      width: 1300px;
      margin: 0 auto;
      padding: 0 0 0;
      .lsh-title{
          font-size: 48px;
          text-align: center;
          color: #333;
          line-height: 48px;
          margin-top: 30px;
          margin-bottom: 20px;
      }
      .factory-main{
        background-color: #f0f0f0;

      }
      .clearfix{
        *zoom: 1;
      }
      .clearfix:after{
        content: "";
        display: block;
        height: 0;
        clear:both;
        visibility: hidden;
      }
    }

 }
</style>
